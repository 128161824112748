import React, { useContext } from "react";
import { Backdrop, Button, Box, Typography, Slide } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import { IncomingCallContext } from "../../contexts/incoming-call.context";
import { CurrentCallContext } from "../../contexts/current-call.context";
// import { PhoneStatusContext } from "../../contexts/phone-status.context";
import { useSIP } from "../../contexts/SIPContext";

import "./incoming-call-notification.styles.scss";

const IncomingCallNotification = ({
  open,
  callerName,
  callerNumber,
  onAnswer,
  onReject,
}) => {
  const {
    incomingCall,
    //  setIncomingCall
  } = useContext(IncomingCallContext);
  const { setCurrentCall } = useContext(CurrentCallContext);
  // const { setIsOnCall } = useContext(PhoneStatusContext);
  const { answerCall, declineCall } = useSIP();
  const handleAnswer = async () => {
    await setCurrentCall({
      display_name: incomingCall._remote_identity._display_name,
      display_number: incomingCall._remote_identity._uri._user,
      call_direction: "inbound",
    });
    answerCall();
    // setIsOnCall(true);
    // setIncomingCall(null);
  };

  const handleReject = () => {
    declineCall();
    // setIncomingCall(null);
  };

  return (
    <Backdrop open={open} className="backdrop">
      <Slide direction="down" in={open} mountOnEnter unmountOnExit>
        <Box className="notificationContainer">
          {/* <Typography variant="h6">Incoming Call</Typography> */}
          <Box className="callerInfo">
            <Typography variant="h6" align="left">
              {callerName}
            </Typography>
            <Typography variant="subtitle2" align="left">
              {callerNumber}
            </Typography>
            <Typography variant="subtitle2" align="left">
              Incoming Call
            </Typography>
          </Box>
          <Box className="buttonsContainer">
            <Button
              variant="contained"
              sx={{
                minWidth: "auto",
                padding: "8px",
                borderRadius: "50%",
              }}
              onClick={handleReject}
            >
              <CallEndIcon sx={{ fontSize: 26 }} />
            </Button>
            <Button
              variant="contained"
              sx={{
                minWidth: "auto",
                padding: "8px",
                borderRadius: "50%",
              }}
              onClick={handleAnswer}
            >
              <CallIcon sx={{ fontSize: 26 }} />
            </Button>
          </Box>
        </Box>
      </Slide>
    </Backdrop>
  );
};

export default IncomingCallNotification;
