// class MediaDeviceHelper {
//   static async getMediaDevices() {
//     const devices = await navigator.mediaDevices.enumerateDevices();
//     const microphones = [];
//     const speakers = [];
//     const cameras = [];

//     const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent); // Check for Safari

//     devices.forEach((device) => {
//       switch (device.kind) {
//         case "audioinput":
//           microphones.push(device);
//           break;
//         case "audiooutput":
//           if (!isSafari) {
//             speakers.push(device); // Safari doesn't support audiooutput, so skip it.
//           }
//           break;
//         case "videoinput":
//           cameras.push(device);
//           break;
//         default:
//           break;
//       }
//     });

//     return {
//       microphones,
//       speakers: isSafari ? [] : speakers,
//       cameras,
//     };
//   }

//   static async getDisplay() {
//     let stream;
//     try {
//       stream = await navigator.mediaDevices.getDisplayMedia();
//       // Here we get the track for the screen. This can be used for screen sharing.
//       const screenTrack = stream.getVideoTracks()[0];
//       return screenTrack;
//     } catch (err) {
//       console.error("Error getting display:", err);
//       throw err;
//     } finally {
//       // Cleanup the stream if we got one
//       if (stream) {
//         stream.getTracks().forEach((track) => track.stop());
//       }
//     }
//   }
// }

// export default MediaDeviceHelper;

// // export default MediaDeviceHelper;

// // const mediaHelper = new MediaDeviceHelper();
// // export { mediaHelper as MediaDeviceHelper };

class MediaDeviceHelper {
  static async getMediaDevices() {
    const microphones = [];
    const speakers = [];
    const cameras = [];

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent); // Check for Safari

    try {
      // Request microphone access before device enumeration, especially for Safari
      if (isSafari) {
        await navigator.mediaDevices.getUserMedia({ audio: true });
      }

      const devices = await navigator.mediaDevices.enumerateDevices();

      devices.forEach((device) => {
        switch (device.kind) {
          case "audioinput":
            microphones.push(device);
            break;
          case "audiooutput":
            // Safari doesn't support full audiooutput enumeration, but try after permission
            speakers.push(device);
            break;
          case "videoinput":
            cameras.push(device);
            break;
          default:
            break;
        }
      });
    } catch (err) {
      console.error("Error accessing media devices:", err);
    }

    return {
      microphones,
      speakers: isSafari ? [] : speakers, // Handle speakers conditionally for Safari
      cameras,
    };
  }

  static async getDisplay() {
    let stream;
    try {
      stream = await navigator.mediaDevices.getDisplayMedia();
      const screenTrack = stream.getVideoTracks()[0];
      return screenTrack;
    } catch (err) {
      console.error("Error getting display:", err);
      throw err;
    } finally {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    }
  }
}

export default MediaDeviceHelper;
