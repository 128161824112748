import { createContext, useState, useEffect } from "react";
import { showNotification } from "../utils/notificationUtil";

export const IncomingCallContext = createContext({
  incomingCall: null,
  setIncomingCall: () => {},
});

export const IncomingCallProvider = ({ children }) => {
  const [incomingCall, setIncomingCall] = useState(null);
  const value = { incomingCall, setIncomingCall };
  useEffect(() => {
    if (incomingCall) {
      console.log("INCOMING...", incomingCall);
      showNotification("Incoming Call", {
        body: `Call from ${incomingCall._remote_identity._display_name} \n ${incomingCall._remote_identity._uri._user}`,
        icon: `${window.location.origin}/favicon.ico`, // Optional: path to an icon
      });
    }
  }, [incomingCall]);
  return (
    <IncomingCallContext.Provider value={value}>
      {children}
    </IncomingCallContext.Provider>
  );
};
